import React, { useState, useEffect } from "react";
import axios from "axios";

import "./index.scss";
import Card from "../Card";
import Preloader from "../../Preloader";
import Image from "../../Image/Image";

const Timeline = ({ page = 1, setPage, setArticlesFetching, type = "aside" }) => {
    const [isActive, setActive] = useState(true);
    const [news, setNews] = useState([]);
    const [offset, setOffset] = useState(1);
    // const [page, setPage] = useState(0);
    const [date, setDate] = useState("all");
    const [loading, setLoading] = useState(true);
    const [scrollFetching, setScrollFetching] = useState(false);

    useEffect(() => {
        let prevScroll = window.pageYOffset;
        window.addEventListener("scroll", function () {
            if (window.innerWidth > 980) {
                let currentScroll = window.pageYOffset;
                if (Math.abs(prevScroll - currentScroll) > 10) {
                    setActive(prevScroll > currentScroll);
                }
                prevScroll = currentScroll;
            }
        });
        axios.post("/lenta", { offset: 1, limit: 20, date }).then(function (response) {
            setNews([...news, ...response.data]);
            setLoading(false);
        });
    }, []);

    const handleScroll = (e) => {
        const height = e.target.scrollHeight;
        const scrolled = e.target.scrollTop + e.target.offsetHeight;
        const threshold = height - scrolled;
        if (threshold < 600 && !scrollFetching) {
            setScrollFetching(true);
            axios.post("/lenta", { offset: offset + 1, limit: 20, date }).then(function (response) {
                setNews([...news, ...response.data]);
                setOffset(offset + 1);
                if (response.data.length > 0) {
                    setScrollFetching(false);
                }
            });
        }
    };

    useEffect(() => {
        if (type === "timeline" && page > 1) {
            axios.post("/lenta", { offset: page, limit: 20, date }).then(function (response) {
                setNews([...news, ...response.data]);
                setArticlesFetching(false);
            });
        }
    }, [page]);

    useEffect(() => {
        setLoading(true);
        setOffset(1);
        if (setPage) {
            setPage(1);
        }
        if (setArticlesFetching) {
            setArticlesFetching(false);
        }
        axios.post("/lenta", { offset: 1, limit: 20, date }).then(function (response) {
            setNews(response.data);
            setLoading(false);
            setScrollFetching(false);
        });
    }, [date]);

    return (
        <aside className="timeline">
            <div target="_blank" rel="nofollow noreferrer" href="https://contract.gosuslugi.ru/?ysclid=lhbw2ymi74462749248" className="timeline__contract">
                <Image src={process.env.PUBLIC_URL + "/images/god2025.jpg"} alt={"2025 объявлен годом защитника Отечества"} />
            </div>
            <a target="_blank" rel="nofollow noreferrer" href="https://contract.gosuslugi.ru/?ysclid=lhbw2ymi74462749248" className="timeline__contract">
                <Image src={process.env.PUBLIC_URL + "/images/contract.jpg"} alt={"Служба по контракту"} />
            </a>
            <div className={isActive ? "timeline__wrapper" : "timeline__wrapper timeline__wrapper_active"}>
                <div className="timeline__head_wrapper">
                    <h4>Лента</h4>
                    <div className="timeline__date_select_wrapper">
                        {date === "today" ? (
                            <div
                                className="timeline__date_select_item"
                                onClick={() => {
                                    setDate("all");
                                }}
                            >
                                Все
                            </div>
                        ) : (
                            <div
                                className="timeline__date_select_item"
                                onClick={() => {
                                    setDate("today");
                                }}
                            >
                                Сегодня
                            </div>
                        )}
                        {date === "tomorrow" ? (
                            <div
                                className="timeline__date_select_item"
                                onClick={() => {
                                    setDate("all");
                                }}
                            >
                                Все
                            </div>
                        ) : (
                            <div
                                className="timeline__date_select_item"
                                onClick={() => {
                                    setDate("tomorrow");
                                }}
                            >
                                Вчера
                            </div>
                        )}
                    </div>
                </div>
                {!loading ? (
                    <div className="timeline__news" onScroll={(e) => handleScroll(e)}>
                        {!!news.length ? news?.map((article, i) => <Card key={i} article={article} type="only-title"></Card>) : <div className="timeline__empty">Здесь пока нет статей</div>}
                    </div>
                ) : (
                    <Preloader></Preloader>
                )}
            </div>
        </aside>
    );
};

export default Timeline;
